import React from 'react';
import { GetResult, GenerateResult } from '../services';
import DetailPage from '../components/DetailPage';
import medicallaboratory from '../img/medicallaboratory.png';
import { useParams } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import DetailsList from '../components/DetailsList';
import { formatCompactDateTime } from '../util/convertDate';
import { generateLink } from '../util/linkHelper';
import MapProvider from '../components/map';

const TestRecordDetailsList = ({ data }) => {
  const fields = [
    [
      { key: 'bacteriaTypeName', label: 'Bacteria Type', icon: 'fa-flask' },
      { key: 'waterTypeName', label: 'Water Type', icon: 'fa-water' },
      { key: 'statusName', label: 'Status', icon: 'fa-rotate' },
    ],
    [
      { 
        key: 'company.name', 
        label: 'Company Name', 
        icon: 'fa-building',
        render: (name) => data.company ? generateLink('company', data.company.companyId, name, 'fa-building') : name
      },
      { 
        key: 'device.name', 
        label: 'Device Name', 
        icon: 'fa-microchip',
        render: (name) => data.device ? generateLink('device', data.device.deviceId, name, 'fa-microchip') : name
      }
    ],
    [
      { 
        key: 'createDateTimeLocal', 
        label: 'Test Date/Time', 
        icon: 'fa-calendar-clock', 
        render: (value) => formatCompactDateTime(value)
      },
      { key: 'slide', label: 'Slide Number', icon: 'fa-microscope' },
    ],
    [
      { 
        key: 'cloudResultId', 
        label: 'Cloud Result ID', 
        icon: 'fa-cloud',
        render: (id) => generateLink('testRecord', id, id, 'fa-cloud')
      },
      { key: 'linkToken', label: 'Link Token', icon: 'fa-link' },
    ],
  ];

  return <DetailsList data={data} fields={fields} />;
};

const SampleDetailsList = ({ sample }) => {
  const fields = [
    [
      { key: 'cloudId', label: 'Sample ID', icon: 'fa-hashtag' },
      { key: 'localId', label: 'Local ID', icon: 'fa-fingerprint' },
      { key: 'waterType', label: 'Water Type', icon: 'fa-water' },
    ],
    [
      { key: 'collectionsSite', label: 'Collection Site', icon: 'fa-map-location' },
      { key: 'residualChlorine', label: 'Residual Chlorine', icon: 'fa-vial' },
      { key: 'observations', label: 'Observations', icon: 'fa-microscope' },
    ],
    [
      { 
        key: 'setDateTimeUtc', 
        label: 'Sampling Date/Time', 
        icon: 'fa-calendar-clock',
        render: (value) => formatCompactDateTime(value)
      },
    ],
  ];

  return (
    <>
      <DetailsList data={sample} fields={fields} />
      <div className="card mt-4">
        <div className="card-header">
          <h6 className="m-0 font-weight-bold text-primary">
            <i className="fa-solid fa-map mr-2"></i>
            Sample Location
          </h6>
        </div>
        <div className="card-body">
          <div style={{ height: '400px', width: '100%' }}>
            <MapProvider
              lat={sample.latitude}
              lng={sample.longitude}
              observations={sample.observations}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const AIPredictionsList = ({ aiPredictions }) => {
  const fields = [
    [
      { key: 'concentration', label: 'Count (CFU/100 ml)', icon: 'fa-chart-line' },
    ],
    [
      { key: 'modelModelVersion', label: 'Model Version', icon: 'fa-code-branch' },
    ],
  ];

  return <DetailsList data={aiPredictions} fields={fields} />;
};

const TestRecordDetailsPage = () => {
  const { id } = useParams();

  const handleDownload = async () => {
    await GenerateResult(id);
  };

  const DetailComponent = ({ data }) => (
    <>
      <Row>
        <Col md={12}>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-gray-800">Test Record Details</h6>
            </div>
            <div className="card-body">
              <TestRecordDetailsList data={data} />
            </div>
          </div>
        </Col>
      </Row>
      {data.sample ? (
        <Row>
          <Col md={12}>
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-gray-800">Sample Details</h6>
              </div>
              <div className="card-body">
                <SampleDetailsList sample={data.sample} />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={12}>
            <Alert color="info">
              No sample information is available for this test record.
            </Alert>
          </Col>
        </Row>
      )}
      {data.aiPredictions ? (
        <Row>
          <Col md={12}>
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-gray-800">AI Predictions</h6>
              </div>
              <div className="card-body">
                <AIPredictionsList aiPredictions={data.aiPredictions} />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={12}>
            <Alert color="info">
              AI predictions are not available for this test record.
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <DetailPage
      title="Result Details"
      fetchDataFunction={GetResult}
      DetailComponent={DetailComponent}
      backUrl="/test-records"
      iconSrc={medicallaboratory}
      actions={[
        {
          label: "Download Result",
          icon: "fa-download",
          onClick: handleDownload,
          className: "btn-primary"
        }
      ]}
    />
  );
};

export default TestRecordDetailsPage;
