import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TopNav from './TopNav';
import SideNav from './SideNav';
import Footer from '../components/footer';
import RoshanLoader from './RoshanLoader';

const DetailPage = ({
    title,
    fetchDataFunction,
    DetailComponent,
    backUrl,
    iconSrc,
    actions = [],
}) => {
    const { id, companyId } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { data, error, isLoading } = useQuery(
        ['getData', id, companyId],
        () => fetchDataFunction(id, companyId),
        {
            refetchOnWindowFocus: false,
            enabled: !!id,
        }
    );

    const handleBack = () => {
        navigate(`${backUrl}?${searchParams.toString()}`);
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Roshan Water | ${title}`}</title>
            </Helmet>
            <TopNav imageSrc={iconSrc} text={title} />
            <SideNav />
            <div className="maincontent">
                <div id="wrapper">
                    <div className="container-fluid main-content">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <div className="d-sm-flex align-items-center flex-grow-1"></div>
                            <div>
                                {actions.map((action, index) => (
                                    <button
                                        key={index}
                                        className={`d-sm-inline-block btn btn-sm shadow-sm mr-2 ${action.className || 'btn-primary'}`}
                                        onClick={() => action.onClick(id)}
                                    >
                                        {action.icon && <i className={`fa-solid ${action.icon} fa-sm text-white-50 mr-1`} />}
                                        {action.label}
                                    </button>
                                ))}
                                <button
                                    className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                                    onClick={handleBack}
                                >
                                    <i className="fa-solid fa-angles-left fa-sm text-white-50 mr-1" /> Back
                                </button>
                            </div>
                        </div>
                        <div id="accordion">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <h6 className="m-0 font-weight-bold text-gray-800">Details</h6>
                                </div>
                                {isLoading ? (
                                    <RoshanLoader />
                                ) : (
                                    <DetailComponent data={data} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default DetailPage;
